@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  flex-direction: column;


}

main {
  flex: 1 0 auto;
  min-height: 100vh;
}
.footer {
  margin-top: 20px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  padding: 8px 12px;
  border-radius: 18px;
  border: 1px solid;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 120px;
  font-size: 15px;
  
}
.btn.primary:hover {
  background-color: #245ecc;
}

.primary {
  background-color: #1D4289;
  color: #eee;
}

.ghost {
  border: 1px solid #1D4289;
  color: #1D4289;
}

.btn.ghost:hover {
  border: 1px solid #245ecc
}


.bg {
  background-color: #e2f4ff;
  max-width: 1300px;
  width: calc(100% - 20px);
  margin: auto;
  border-radius: 8px;
}

.form-bg {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
}

.loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255);
}

.loader p {
  font-size: 16px;
  color: black;
  margin-top: 16px;
  z-index: 99999;
}


.logo {
  width: 300px;
}

.logo-spin{
  width: 100px;
}

.logo-sm {
  width: 100px;
  display: inline-block;
}

.Mui-active {
  color: "#8cc638",
}

.bottom-nav {
  background-color: #e2f4ff;
    height: 80px;
    color: #272C47;
    font-size: 18px;
    box-shadow: 2px 1px 2px 2px rgba(161, 161, 161, 0.2);
}

.phcn-btn {
  padding: 10px;
  border-radius: 18px;
  border: 1px solid;
  text-transform: uppercase;
  min-width: 120px;
  font-size: 15px;
  font-weight: 500;

}

.default-signature {
  border: 1px solid #e5e7eb;
  margin-left: auto;
  margin-right: auto;
}

.file-list {
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.file-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.file-list li {
  margin-bottom: 10px;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  min-width: 400px;
  max-width: 500px;
}

.file-name {
  font-weight: bold;
}

.close-icon {
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #333;
}

.secure-page-container {
  max-width: 800px;
}

.error-message {
  font-size: 12px;
  color: #f44336;
}